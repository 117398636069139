import React from 'react';

import "./riciclo-section.scss";
import contents from "./riciclo-section.yml";
const RicicloSection = (props) => {

  const {
    riciclo
  } = contents;


  return (
    <div className={`riciclo-section__container`}>
      <h5>{riciclo.title}</h5>
      <img
        className="riciclo-section__container__image"
        alt={riciclo.image.alt}
        src={riciclo.image.fullpath}
      />
      <p>{riciclo.text}</p>
    </div>

  )
}

export default RicicloSection;
