import React from 'react';

import "./flex-box-images-section.scss";
import BackgroundImage from "gatsby-background-image";
import {graphql, useStaticQuery} from "gatsby";
import contents from "./flexBoxImagesSection.yml";

const FlexBoxImagesSection = (props) => {

  const { desktopMilano, desktopRoma } = useStaticQuery(
    graphql`
      query {
        desktopMilano: file(relativePath: { eq: "leggende-di-strada/Collage_Milano.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1024) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
         desktopRoma: file(relativePath: { eq: "leggende-di-strada/Collage_Roma.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1024) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  const {
    Milano,
    Roma
  } = contents;

  const imageDataMilano = desktopMilano.childImageSharp.fluid
  const imageDataRoma = desktopRoma.childImageSharp.fluid

  return (
    <div className={`flex-box-images-section__container`}>
      <div className="flex-box-images-section__wrapper">
        <div className="flex-box-images-section__single-box">
          <div className="flex-box-images-section__overlay"></div>
          <BackgroundImage
            Tag="div"
            className="inherit-height"
            fluid={imageDataRoma}
            backgroundColor={`transparent`}
            role="img"
            preserveStackingContext={true} />

          <div className="flex-box-images-section__single-box__content">
          </div>


        </div>
        <div className="flex-box-images-section__single-box">
          <BackgroundImage
            Tag="div"
            className="inherit-height"
            fluid={imageDataMilano}
            backgroundColor={`transparent`}
            role="img"
            preserveStackingContext={true} />

          <div className="flex-box-images-section__single-box__content">
          </div>


        </div>
      </div>
    </div>

  )
}

export default FlexBoxImagesSection;
