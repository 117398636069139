import React from "react";
import PropTypes from "prop-types";
import Modal from 'react-modal';

import ButtonClose from '../buttonClose';

import './popup.scss';

Modal.setAppElement(`#___gatsby`);
Modal.defaultStyles.overlay.backgroundColor = 'rgba(0,0,0,.7)';

export const PopupText = ({ modalIsOpen, closeModal, ...props }) => {

  const {
    onClick,
    name,
    otherInformation,
    title,
    text,
    image
  } = props;

  // eslint-disable-next-line no-console

  return (

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="popup-class"
        closeTimeoutMS={300}
      >
        <ButtonClose
          onClick={onClick}
        />
        <div className="popup__wrapper">
          <div className="popup__image" style={{backgroundImage: `url(${image})`}}>
            <img src={image} />
          </div>
          <div className="popup__text">
            <div className="popup__text--inner">
              <h1>{name}</h1>
              <span>{otherInformation}</span>
              <h3>{title}</h3>
              <p>{text}</p>
            </div>

          </div>

        </div>
      </Modal>


  )
};

PopupText.propTypes = {
  onClick: PropTypes.func.isRequired,
  name: PropTypes.string,
  otherInformation : PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.string,
};
