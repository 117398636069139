import React from 'react';
import { OutboundLink } from "gatsby-plugin-google-gtag"
import PageTransition from '../pageTransition';
import PropTypes from "prop-types";
import {handleGaEvents} from "../../helpers/handleGaEvents";
import "./button-link.scss";

const ButtonLink = (props) => {

  const {
    to,
    target,
    trackingCategory,
    trackingLabel,
    label,
    customClass
  } = props;

  return (
    <>
      {props.target === "_blank" ?
        <OutboundLink
          className={`button-link ${customClass}`}
          href={to}
          target={target}
          rel="noopener noreferrer"
          // eventLabel={label}
        >
          <span>
            {label}
          </span>
        </OutboundLink>
        :
          props.to ?
            <PageTransition
              className={`button-link ${customClass}`}
              to={to}
              onClick={e => {
                e.preventDefault()
                handleGaEvents( "Click", `${trackingCategory}`,`${trackingLabel}`)
              }}
            >
              <span>
                {label}
              </span>
            </PageTransition>
          :
            <button
              className={`button-link ${customClass}`}
              onClick={props.onClick}
            >
              <span>
                {label}
              </span>
            </button>
      }
    </>
  )
};

ButtonLink.propTypes = {
  to: PropTypes.string,
  target: PropTypes.string,
  trackingCategory: PropTypes.string,
  trackingLabel: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

export default ButtonLink;
