import React, {useState} from "react";
import Swiper from "react-id-swiper";
import 'swiper/css/swiper.css';
import "./swiper-leggende-di-strada.scss";
import ButtonLink from "../buttonLink"
import contents from "./swiperLeggendeDiStrada.yml";
import {PopupText} from "../popupText";

const SwiperLeggendeDiStrada = () => {

  const {
    slides,
    titleSection
  } = contents;

  const [popupIsOpen, setIsOpen] = useState(false);
  const [popupImage, setPopupImage] = useState(null);
  const [popupOtherInfo, setPopupOtherInfo] = useState(null);

  const [popupName, setPopupName] = useState(null);
  const [popupTitle, setPopupTitle] = useState(null);
  const [popupText, setPopupText] = useState(null);

  const openModal = (name, otherInfo, title, text, innerImage) => {
    setIsOpen(true);
    setPopupName(name);
    setPopupOtherInfo(otherInfo);
    setPopupTitle(title);
    setPopupText(text);
    setPopupImage(innerImage);
  };
  const closeModal = () => {
    setIsOpen(false);
    setPopupName(null);
    setPopupOtherInfo(null);
    setPopupTitle(null);
    setPopupText(null);
    setPopupImage(null);
  };

  const paramsSwiper = {
    slidesPerView: 1.5,
    spaceBetween: 32,
    a11y: {
      prevSlideMessage: 'Slide precedente',
      nextSlideMessage: 'Slide successiva',
      firstSlideMessage: 'Questa è la prima slide',
      lastSlideMessage: 'Questa è l’ultima slide'
    },
    speed: 500,
    watchOverflow: false,
    watchSlidesVisibility: false,
    centeredSlides: true,
    slideToClickedSlide: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      type: 'progressbar',
    },
    breakpoints: {
      1024: {
        spaceBetween: 42,
        slidesPerView: 3,
        centeredSlides: false,
      },
        768: {
          spaceBetween: 42,
          slidesPerView: 1.75,
          centeredSlides: true,
          //slidesOffsetBefore: 100
      }
    }
  };

  return (
    <div className="swiper-leggende-di-strada">
      <div className="container--medium">
        <h2 dangerouslySetInnerHTML={{__html: titleSection}}></h2>
      </div>
      <div className="container--padding-left container--no-padding-under-desktop">
        <Swiper containerClass="swiper-container swiper-leggende-di-strada__container " {...paramsSwiper}>
          {slides.map((slide, i) => {
            return (
              <div key={i} onClick={() => { openModal(slide.name, slide.otherInfo, slide.title, slide.text, slide.innerImage.fullpath); } } className="swiper-slide swiper-leggende-di-strada__slide">
                <div className="swiper-leggende-di-strada__image">
                  <img
                    className="swiper-leggende-di-strada__image__single"
                    alt={slide.image.alt}
                    src={slide.image.fullpath}
                  />
                  <div className="swiper-leggende-di-strada__content">
                    <div className="swiper-leggende-di-strada__content__name">
                      {slide.name}
                    </div>
                    <div className="swiper-leggende-di-strada__content__other-info">
                      {slide.otherInfo}
                    </div>
                    <div className="swiper-leggende-di-strada__content__cta">
                      <ButtonLink
                        label={slide.link.text}
                        onClick={() => {openModal(slide.name, slide.otherInfo, slide.title, slide.text, slide.innerImage.fullpath); } }
                        customClass="button-link--plus-icon"
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Swiper>

      </div>

      <PopupText
        modalIsOpen={popupIsOpen}
        onClick={closeModal}
        name={popupName}
        otherInformation={popupOtherInfo}
        title={popupTitle}
        text={popupText}
        image={popupImage}
      />
    </div>
  );
};

export default SwiperLeggendeDiStrada;
