import React from 'react';

import "./fullTextContent.scss";

function createMarkup(html) { return {__html: html}; }

const Markup = (props) => {
  const Tag = props.tag;
  return <Tag className = {props.class} dangerouslySetInnerHTML = {createMarkup(props.content)} />
};


const FullTextContent = (props) => {

  const {
    children
  } = props;

  return (
    <div className={`FullTextContent`}>
      <div className="inner-content">
        <Markup
          tag={props.whichTitle}
          content={props.copyTitle}
        />
        <div dangerouslySetInnerHTML={{__html: children}} />
      </div>
    </div>
  )
};

export default FullTextContent;
