export const handleGaEvents = (event, category, label) => {
  if (typeof window.gtag !== 'undefined' && typeof window !== "undefined") {

    window.gtag('event', event, {
      'category': category,
      'label': label,
      'url': window.location.href,
      'event_timeout': 1500,
      'debug_mode': true
    });

  }
}

