import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImage from 'gatsby-background-image'

const BackgroundLeggendeDiStrada = () => {
  const { desktop, medium } = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "leggende-di-strada/leggende-di-strada-desktop.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
         medium: file(relativePath: { eq: "leggende-di-strada/leggende-di-strada-tablet.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1024) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }  
      }
    `
  )
  const imageData = [
    {
      ...medium.childImageSharp.fluid,
      media: `(max-width: 1024px)`
    },
    {
      ...desktop.childImageSharp.fluid,
      media: `(min-width: 1025px)`,
    },
  ]

  return (
    <BackgroundImage
      Tag="div"
      className=""
      fluid={imageData}
      backgroundColor={`transparent`}
      role="img"
      preserveStackingContext={true} />
  )
};

export default BackgroundLeggendeDiStrada;
