import React, {useState} from "react";
import Swiper from "react-id-swiper";
import 'swiper/css/swiper.css';
import "./swiper-le-nostre-leggende.scss";
import ButtonLink from "../buttonLink"
import contents from "./swiperLeNostreLeggende.yml";
import {PopupText} from "../popupText";

const SwiperLeNostreLeggende = () => {

  const {
    titleSection,
    slides,
  } = contents;

  const [popupIsOpen, setIsOpen] = useState(false);
  const [popupImage, setPopupImage] = useState(null);
  const [popupOtherInfo, setPopupOtherInfo] = useState(null);

  const [popupName, setPopupName] = useState(null);
  const [popupTitle, setPopupTitle] = useState(null);
  const [popupText, setPopupText] = useState(null);

  const openModal = (name, otherInfo, title, text, innerImage) => {
    setIsOpen(true);
    setPopupName(name);
    setPopupOtherInfo(otherInfo);
    setPopupTitle(title);
    setPopupText(text);
    setPopupImage(innerImage);
  };
  const closeModal = () => {
    setIsOpen(false);
    setPopupName(null);
    setPopupOtherInfo(null);
    setPopupTitle(null);
    setPopupText(null);
    setPopupImage(null);
  };

  const params = {
    slidesPerView: 1.5,
    spaceBetween: 32,
    a11y: {
      prevSlideMessage: 'Slide precedente',
      nextSlideMessage: 'Slide successiva',
      firstSlideMessage: 'Questa è la prima slide',
      lastSlideMessage: 'Questa è l’ultima slide'
    },
    speed: 500,
    watchOverflow: true,
    watchSlidesVisibility: true,
    centeredSlides: true,
    slideToClickedSlide: true,
    breakpoints: {
      1024: {
        spaceBetween: 42,
        slidesPerView: 3,
        centeredSlides: false,
      },
      768: {
        spaceBetween: 42,
        slidesPerView: 1.75,
        centeredSlides: true,
      }
    }
  };

  return (
    <div className="swiper-le-nostre-leggende">
      <div className="container--medium">
        <h2 dangerouslySetInnerHTML={{__html: titleSection}}></h2>
      </div>
      <div className="container--medium container--no-padding-under-desktop">
        <Swiper containerClass="swiper-container swiper-le-nostre-leggende__container" {...params}>
          {slides.map((slide, i) => {
            return (
              <div key={i} onClick={() => {
                openModal(slide.name, slide.otherInfo, slide.title, slide.text, slide.innerImage.fullpath);
              }}
                   className="swiper-slide swiper-le-nostre-leggende__slide">
                <div className="swiper-le-nostre-leggende__image">
                  <img
                    className="swiper-le-nostre-leggende__image__single"
                    alt={slide.image.alt}
                    src={slide.image.fullpath}
                  />
                  <div className="swiper-le-nostre-leggende__content">
                    <div className="swiper-le-nostre-leggende__content__name">
                      {slide.name}
                    </div>
                    <div className="swiper-le-nostre-leggende__content__other-info">
                      {slide.otherInfo}
                    </div>
                    <div className="swiper-le-nostre-leggende__content__cta">
                      <ButtonLink
                        label={slide.link.text}
                        onClick={() => {
                          openModal(slide.name, slide.otherInfo, slide.title, slide.text, slide.innerImage);
                        }}
                        customClass="button-link--plus-icon"
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Swiper>

      </div>
      <PopupText
        modalIsOpen={popupIsOpen}
        onClick={closeModal}
        name={popupName}
        otherInformation={popupOtherInfo}
        title={popupTitle}
        text={popupText}
        image={popupImage}
      />
    </div>
  );
};

export default SwiperLeNostreLeggende;
