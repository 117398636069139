import React, {useState} from 'react';
import FullTextContent from "../fullTextContent/fullTextContent";

import contents from "./VideoSectionLeggendeDiStrada.yml";

import './video-section-leggende-di-strada.scss'
import {ModalYoutube} from "../modals";
import BackgroundImg from "../backgroundImg";
import PlaySvg from "../../images/svg/play-white.svg";
import BackgroundImage from "gatsby-background-image";
import {graphql, useStaticQuery} from "gatsby";


const VideoSectionLeggendeDiStrada = () => {

  const { desktop } = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "leggende-di-strada/video-preview.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  const imageData = desktop.childImageSharp.fluid

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalYoutubeVideoId, setModalYoutubeVideoId] = useState(null);
  const openModal = (videoId) => {
    setModalYoutubeVideoId(videoId);
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
    setModalYoutubeVideoId(null);
  };

  const {
    youtubeVideoId,
    label
  } = contents
  return (
    <section className="video-content__wrapper">
    <div
      className="video-content"
    >
      <button
        className="video-content__video"
        onClick={ () => { openModal(youtubeVideoId); } }
      >
        <BackgroundImage
          Tag="div"
          className="inherit-height"
          fluid={imageData}
          backgroundColor={`transparent`}
          role="img"
          preserveStackingContext={true} />
        <PlaySvg
          className="play-button"
        />
      </button>

    </div>
      <ModalYoutube
        modalIsOpen={modalIsOpen}
        onClick={closeModal}
        videoID={modalYoutubeVideoId}
        label={label}
      />
    </section>
  )
};

export default VideoSectionLeggendeDiStrada;
