import React from "react";
import PropTypes from "prop-types";

import "./backgroundImg.scss";

const BackgroundImg = (props) => {

  const {
    className,
    src,
    alt
  } = props;

  return (
    <div
      className={`${className} BackgroundImg`}>
      <div
        role="img"
        aria-label={alt}
        style={{
          backgroundImage: `url(${src})`
        }}
      />
    </div>
  );

};

BackgroundImg.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string
};

export default BackgroundImg;
