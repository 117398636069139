import React from 'react';
import FullTextContent from "../fullTextContent/fullTextContent";

import contents from "./leggendeDiStradaHeroContent.yml";

import './leggende-di-strada-hero-content.scss'

const LeggendeDiStradaHeroContent = () => {

  const {
    title,
    body
  } = contents;

  return (
    <FullTextContent
      whichTitle="h1"
      copyTitle={title}
    >{body}</FullTextContent>
  )
};

export default LeggendeDiStradaHeroContent;
