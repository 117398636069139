import React from "react"
import SEO from "../components/seo"
import HeroImageSectionLeggendeDiStrada from "../components/heroImageSectionLeggendeDiStrada/HeroLeggendeDiStrada";
import LeggendeDellaStradaHeroContent from "../components/leggendeDiStradaHeroContent/LeggendeDiStradaHeroContent";
import TattooBackground from "../components/backgroundImages/tattooBackground";
import DiventaUnaLeggendaContent from "../components/diventaUnaLeggendaContent/DiventaUnaLeggendaContent";
import FlexBoxImagesSection from "../components/flexBoxImagesSection/FlexBoxImagesSection";
import RicicloSection from "../components/ricicloSection/RicicloSection";
import SwiperLeNostreLeggende from "../components/swiperLeNostreLeggende/SwiperLeNostreLeggende";
import SwiperLeggendeDiStrada from "../components/swiperLeggendeDiStrada/SwiperLeggendeDiStrada";
import VideoSectionLeggendeDiStrada from "../components/VideoSectionLeggendeDiStrada/VideoSectionLeggendeDiStrada";

const LeggendeDiStrada = () => (
  <main>
    <SEO
      title="Leggende di Strada"
      description="Non tutti gli eroi indossano un mantello, ad alcuni basta un tatuaggio dietro al quale si nascondono storie d’amore, avventure, aneddoti epici e fantasie diventate realtà."
    />

    <div className="tattoo__background-wrapper tattoo__background--overlap-bottom">

      <TattooBackground/>

      <HeroImageSectionLeggendeDiStrada/>

      <div className="container--medium">
        <LeggendeDellaStradaHeroContent/>

      </div>

      <div className="container--medium container--no-padding-mobile">
        <VideoSectionLeggendeDiStrada/>
      </div>

      <div className="tattoo__background--container">

        <SwiperLeNostreLeggende/>

        <SwiperLeggendeDiStrada />

        <div className="tattoo__background--clip-yellow">
        </div>

      </div>

      <div className="container--medium">

        <DiventaUnaLeggendaContent/>
        <FlexBoxImagesSection/>
        <RicicloSection/>
      </div>

    </div>

  </main>
);

export default LeggendeDiStrada;
