import React from 'react';
import FullTextContent from "../fullTextContent/fullTextContent";

import contents from "./DiventaUnaLeggendaContent.yml";

const DiventaUnaLeggendaContent = () => {

  const {
    title,
    body
  } = contents;

  return (
    <FullTextContent
      whichTitle="h2"
      copyTitle={title}
    >{body}</FullTextContent>
  )
};

export default DiventaUnaLeggendaContent;
