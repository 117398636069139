import React from 'react';

import BackgroundLeggendeDiStrada from "../backgroundImages/backgroundLeggendeDiStrada";
import "./hero-image-section-leggende-di-strada.scss";


const HeroImageSectionLeggendeDiStrada = () => {


  return (
    <div className={"hero-section-image__container"}>
      <BackgroundLeggendeDiStrada />
    </div>
  )
};

export default HeroImageSectionLeggendeDiStrada;
